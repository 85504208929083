<template>
  <div class="article" @scroll="handleScroll">
    <error :errMsg="articleMsg" v-if="!isPreview && articleError" />
    <div v-else class="content" id="content" v-show="isPageComplete">
      <div ref="contentDom">
        <div class="title-group">
          <div class="title" v-if="data && data.title">{{ data.title }}</div>
          <div class="date" v-if="data && data.publishTime">{{ data.publishTime }}</div>
        </div>
        <!-- 名片 -->
        <template v-if="data && data.hasCard && cardId">
          <card :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'article'"></card>
        </template>
        <div v-html="data && data.content" class="article-content"></div>
        <!-- 聊一聊 -->
        <template v-if="data && data.hasCard && cardId">
          <fixed-button :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'article'"></fixed-button>
        </template>
        <div class="footer" v-if="data && data.dataVo">
          阅读
          <span class="num m-r-16">{{ data.dataVo.pv }}</span> 分享
          <span class="num">{{ data.dataVo.sharePv }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Article from '@/api/article';
import Common from '@/api/common';
import wxFn from '@/util/wxFn';
import trackFn from '@/util/trackFn';

export default {
  data() {
    return {
      paramsData: {}, // 存放接口参数
      data: null,
      eventId: null,
      isPreview: false,
      pageOverLay: false,
      cmeId: '',
      cardId: '',
      extField: '',
      articleError: false,
      articleMsg: '该文章已关闭',
      cardInfo: {},
      trackDataTemp: {
        durationTimes: null,
        isFinished: null
      },
      trackData: {}
    };
  },
  computed: {
    isPageComplete() {
      let c = (this.data && this.data.publishState && !this.pageOverLay) || (this.isPreview && this.data);
      if (c && !this.isPreview) {
        setTimeout(() => {
          const offH = this.$refs.contentDom.offsetHeight;
          const sh = document.body.scrollHeight || document.docuemntElement.scrollHeight;
          if (offH <= sh) {
            this.pushTrackData();
          }
        }, 100);
      }
      return c;
    }
  },
  mounted() {
    if (this.$route.query.cmeId) {
      this.pageOverLay = true;
      if (this.$route.query.from && this.$route.query.from === 'draft') {
        this.getDraftData(this.$route.query.cmeId);
        this.isPreview = true;
      } else if (this.$route.query.from && this.$route.query.from === 'preview') {
        this.getPreviewData(this.$route.query.cmeId);
        this.isPreview = true;
      } else {
        this.getArticleData(this.$route.query.cmeId);
        this.initWx();
      }
    }
  },
  methods: {
    async getDraftData(id) {
      try {
        const { content } = await Common.getPreviewContent({ id });
        if (content.title) {
          document.title = content.title;
        }
        if (content.content) {
          content.content = JSON.parse(content.content);
        }
        this.data = content;
      } catch (error) {
        console.error('error', error);
        this.$toast(error.msg || '网络错误');
      }
    },
    async getPreviewData(id) {
      try {
        const data = await Article.getArticleContent({ id });
        this.data = data;
        if (data.title) {
          document.title = data.title;
        }
        if (data.content) {
          data.content = JSON.parse(data.content);
        }
      } catch (error) {
        this.$toast(error.msg || '网络错误');
      }
    },
    async getArticleData(id) {
      try {
        const data = await Article.getArticleContent({ id });
        this.data = data;
        if (data.title) {
          document.title = data.title;
        }
        if (data.content) {
          data.content = JSON.parse(data.content);
        }
      } catch (error) {
        if (error.code && error.code === 'C41030') {
          this.articleError = true;
          this.articleMsg = '该文章已关闭';
        } else if (error.code && error.code === 'C41031') {
          this.articleError = true;
          this.articleMsg = '该文章已删除';
        } else {
          this.$toast(error.msg || '网络错误');
        }
      }
    },
    async initWx() {
      try {
        const wxData = await wxFn.init(['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation']);
        // 这里写微信的接口
        let paramsData = wxData;
        this.paramsData = wxData;
        this.cardId = paramsData.params.saleId ? paramsData.params.saleId : '';
        this.cmeId = paramsData.params.cmeId;
        this.extField = JSON.stringify(paramsData.params);
        if (this.cardId) {
          this.getInfo();
        } else {
          this.pageOverLay = false;
        }
        wxFn.initWxShareShare(paramsData, this.data.title, this.data.summary, this.data.coverImg, 'article', this.data);
        // 触发埋点 浏览
        this.trackData = { ...this.data, ...this.trackDataTemp };
        // 浏览文章IN 触发IN事件埋点
        // trackFn.articleViewTrace(paramsData, this.trackData, id => {
        //   console.log('id', id);
        //   this.eventId = id;
        // });
        // 浏览文章IN（大米要求必传的事件）
        trackFn.contentViewTrace(paramsData, this.trackData, 'IN', 'article');

        // 浏览表单OUT 注册OUT事件埋点（注册事件，sdk进行浏览器关闭事件上报 ）
        // trackFn.viewOutTrace('article_read', paramsData, {
        //   article_id: trackData.id,
        //   article_title: trackData.title,
        //   is_finished: this.trackDataTemp.isFinished ? this.trackDataTemp.isFinished : null
        // });
        // trackFn.contentOutViewTrace(paramsData, this.trackData, 'article');
      } catch (error) {
        console.error('error', error);
        this.$toast(error.message || '网络错误');
      }
    },
    // 获取名片信息
    async getInfo() {
      try {
        const params = {
          cardId: this.cardId,
          cmeId: this.cmeId,
          extField: this.extField
        };
        this.cardInfo = await Common.getCardInfo(params);
        this.$store.commit('app/SET_CARD_DATA', this.cardInfo);
        this.pageOverLay = false;
      } catch (error) {
        this.$toast(error.msg || '请求异常');
      }
    },
    // 获取是否滚动到底部
    handleScroll() {
      let innerHeight = window.innerHeight;
      let realHeight = event.target.scrollHeight;
      let diff = realHeight - innerHeight;
      let scrollTop = event.target.scrollTop;
      // 滚动条到底部的条件
      if (scrollTop >= diff && !this.isPreview) {
        this.pushTrackData();
      }
    },
    // 推送埋点数据补充 ： is_finished为true
    pushTrackData() {
      // console.log('envent', this.eventId);
      // trackFn.viewOutTracePush('article_read', {
      //   is_finished: true
      // });
      console.log('浏览完成事件');
      if (sessionStorage.getItem('viewEnd') === 'complete') return;
      trackFn.viewComplete(this.paramsData, this.trackData, 'article');
      sessionStorage.setItem('viewEnd', 'complete');
    }
  }
};
</script>

<style lang="less" scoped>
.article {
  //padding: 26px 20px;
  //width: 335px;
  overflow: auto;
  height: 100vh;
  //height: calc(100vh - 36px);
}
.content {
  padding: 26px 20px;
  box-sizing: border-box;
}
.title-group {
  margin-bottom: 16px;
}
.article-content {
  margin-top: 16px;
}
.title {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.date {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 12px;
}

.footer {
  margin-top: 24px;
  font-size: 12px;
  color: #999999;
  .num {
    color: #4d78ff;
  }
  .m-r-16 {
    margin-right: 16px;
  }
}
</style>
